import styled from '@emotion/styled';
import { graphql, Link, useStaticQuery, navigate } from 'gatsby';
import { ComponentMenuLinkFragment, NavigationQuery } from 'graphql-types';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { motion, useAnimation, useTransform, useViewportScroll } from 'framer-motion';
import { useKey } from 'react-use';
import { useLocation, WindowLocation } from '@reach/router';
import { StaticImage } from 'gatsby-plugin-image';
import { GridContainer, maxMediaQuery, minMediaQuery } from '../grid';
import { mapContentMenuLinksToProps } from '../../utils/content/menu';
import { MenuLink } from '../../models/content/menu-link';
import NavigationList from './NavigationList';
import NavigationToggle from './NavigationToggle';
import NavigationMobile from './NavigationMobile';
import { useBreakpoint } from '../../hooks/breakpoint';
import { DropdownItem } from '../dropdown/props';
import Dropdown from '../dropdown/Dropdown';
import Icon from '../icon/Icon';
import NavigationMobileCountryList from './NavigationMobileCountryList';
import NavigationMobileCountryToggle from './NavigationMobileCountryToggle';
import {
  DESKTOP_NAVIGATION_HEIGHT,
  SMALL_DESKTOP_NAVIGATION_HEIGHT,
  DESKTOP_NAVIGATION_OFFSET,
  DESKTOP_NAVIGATION_SAFE_OFFSET,
  DESKTOP_LOGO_HEIGHT,
  SMALL_DESKTOP_LOGO_HEIGHT,
  MOBILE_NAVIGATION_HEIGHT,
  MOBILE_NAVIGATION_SAFE_OFFSET,
  MOBILE_LOGO_HEIGHT,
} from '../../constants/navigation';
import { useScrollLock } from '../../hooks/useScrollLock';

type Direction = 'top' | 'bottom';

const Navigation: FC = () => {
  const navigationData = useStaticQuery<NavigationQuery>(graphql`
    fragment ComponentMenuLink on ContentfulMenuLink {
      id
      label
      linkUrl
      targetEntry {
        ... on Node {
          ...ArticleEntrySlug
          ...StoryEntrySlug
          ...ProjectEntrySlug
          ...PublicationEntrySlug
          ...PageEntrySlug
        }
      }
      nestedElements {
        id
        label
        linkUrl
        targetEntry {
          ... on Node {
            ...ArticleEntrySlug
            ...StoryEntrySlug
            ...ProjectEntrySlug
            ...PublicationEntrySlug
            ...PageEntrySlug
          }
        }
      }
    }

    fragment ComponentMenu on ContentfulMenu {
      menuLinks {
        ... on Node {
          ...ComponentMenuLink
        }
      }
    }

    fragment ComponentNavigation on ContentfulSiteHeader {
      menu {
        ... on Node {
          ...ComponentMenu
        }
      }
      enableCountryMenu
      countryMenu {
        ... on Node {
          ...ComponentMenu
        }
      }
    }

    query Navigation {
      allContentfulSiteHeader {
        nodes {
          ... on Node {
            ...ComponentNavigation
          }
        }
      }
    }
  `);
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { ref, enableScroll, disableScroll } = useScrollLock<HTMLDivElement>();
  const location = useLocation();
  const { md: breakpointMd } = useBreakpoint();
  const lastPositionRef = useRef(0);
  const lastDirectionRef = useRef<Direction>('bottom');
  const lastLocationRef = useRef<WindowLocation<unknown>>(location);
  const { pathname: currentPath } = location;
  const { scrollY } = useViewportScroll();
  const navigationHeight = useTransform(
    scrollY,
    [0, DESKTOP_NAVIGATION_OFFSET],
    [DESKTOP_NAVIGATION_HEIGHT, SMALL_DESKTOP_NAVIGATION_HEIGHT]
  );
  const logoHeight = useTransform(
    scrollY,
    [0, DESKTOP_NAVIGATION_OFFSET],
    [DESKTOP_LOGO_HEIGHT, SMALL_DESKTOP_LOGO_HEIGHT]
  );
  const navigationControls = useAnimation();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [mobileCountryNavOpen, setMobileCountryNavOpen] = useState(false);
  const [countrySelectorValue, setCountrySelectorValue] = useState<string | undefined>(undefined);
  const enableCountryMenu = navigationData?.allContentfulSiteHeader?.nodes?.[0]?.enableCountryMenu ?? false;

  const menuLinks = useMemo<MenuLink[]>(() => {
    const menu =
      (navigationData?.allContentfulSiteHeader?.nodes?.[0]?.menu?.menuLinks as ComponentMenuLinkFragment[]) ??
      undefined;

    if (menu != null) {
      return mapContentMenuLinksToProps(menu);
    }

    return [];
  }, [navigationData]);

  const countrySelectorOptions = useMemo<DropdownItem[]>(() => {
    const menu =
      (navigationData?.allContentfulSiteHeader?.nodes?.[0]?.countryMenu?.menuLinks as ComponentMenuLinkFragment[]) ??
      undefined;

    if (menu != null) {
      const mappedLinks = mapContentMenuLinksToProps(menu);

      return mappedLinks.map((link) => ({
        key: link.key,
        value: link.url || link.key,
        label: link.label,
      }));
    }

    return [];
  }, [navigationData]);

  const countryMenuLinks = useMemo<MenuLink[]>(() => {
    const menu =
      (navigationData?.allContentfulSiteHeader?.nodes?.[0]?.countryMenu?.menuLinks as ComponentMenuLinkFragment[]) ??
      undefined;

    if (menu != null) {
      return mapContentMenuLinksToProps(menu);
    }

    return [];
  }, [navigationData]);

  const handleMovingBottom = useCallback(() => {
    const { current: lastDirection } = lastDirectionRef;

    if (lastDirection === 'top') {
      navigationControls.start({
        y: '-100%',
        transitionEnd: {
          position: 'relative',
        },
      });
    }
  }, [navigationControls]);

  const handleMovingTop = useCallback(
    (position: number) => {
      const { current: lastDirection } = lastDirectionRef;
      const offset = breakpointMd === false ? 0 : DESKTOP_NAVIGATION_OFFSET;

      if (lastDirection === 'bottom') {
        navigationControls.set({
          y: '-100%',
          position: 'fixed',
        });
        navigationControls.start({
          y: 0,
        });
      } else if (position <= offset) {
        navigationControls.set({
          position: 'relative',
        });
      }
    },
    [navigationControls, breakpointMd]
  );

  const handleScrollYMove = useCallback(
    (position: number) => {
      const { current: lastPosition } = lastPositionRef;
      const safeZone = breakpointMd === false ? MOBILE_NAVIGATION_SAFE_OFFSET : DESKTOP_NAVIGATION_SAFE_OFFSET;

      if (position > lastPosition && position > safeZone) {
        handleMovingBottom();

        lastDirectionRef.current = 'bottom';
      } else if (position < lastPosition) {
        handleMovingTop(position);

        lastDirectionRef.current = 'top';
      }

      lastPositionRef.current = position;
    },
    [navigationControls, breakpointMd, handleMovingBottom, handleMovingTop]
  );

  const handleMobileNavToggle = useCallback(() => {
    setMobileNavOpen((currentValue) => {
      const newState = !currentValue;

      if (newState === true) {
        disableScroll();
      } else {
        enableScroll();
      }

      return newState;
    });
  }, [setMobileNavOpen]);

  const handleMobileNavOpen = useCallback(() => {
    setMobileNavOpen(true);
    disableScroll();
  }, [setMobileNavOpen, disableScroll]);

  const handleMobileNavClose = useCallback(() => {
    setMobileNavOpen(false);
    enableScroll();
  }, [setMobileNavOpen, enableScroll]);

  const handleMobileCountryNavToggle = useCallback(() => {
    setMobileCountryNavOpen((currentValue) => {
      const newState = !currentValue;

      if (newState === true) {
        disableScroll();
      } else {
        enableScroll();
      }

      return newState;
    });
  }, [setMobileNavOpen]);

  const handleCountrySelectorItem = useCallback((itemValue: string) => {
    navigate(itemValue);
  }, []);

  useEffect(() => {
    const unsubscribeScrollY = scrollY.onChange(handleScrollYMove);

    return () => {
      unsubscribeScrollY();
    };
  }, [scrollY, handleScrollYMove]);

  useEffect(() => {
    const lastLocation = lastLocationRef.current;

    if (`${location.pathname}${location.search}` !== `${lastLocation.pathname}${lastLocation.search}`) {
      handleMobileNavClose();
    }
  }, [location.pathname, location.search, handleMobileNavClose]);

  useEffect(() => {
    const currentCountry = countrySelectorOptions.find((option) => option.value === currentPath);

    setCountrySelectorValue(currentCountry != null ? currentCountry.value : undefined);
  }, [currentPath, countrySelectorOptions]);

  useEffect(() => {
    if (mobileCountryNavOpen === true) {
      setMobileNavOpen(false);
    }
  }, [mobileCountryNavOpen, setMobileNavOpen]);

  useEffect(() => {
    if (mobileNavOpen === true) {
      setMobileCountryNavOpen(false);
    }
  }, [mobileNavOpen, setMobileCountryNavOpen]);

  // Close mobile navigation when escape key pressed
  useKey('Escape', handleMobileNavClose);

  return (
    <>
      <TopContainer>
        <Container
          animate={navigationControls}
          transition={{ ease: 'anticipate', duration: 0.5 }}
          style={{
            height: navigationHeight,
          }}
        >
          {enableCountryMenu === true && (
            <NavigationMobileCountryList
              items={countryMenuLinks}
              open={mobileCountryNavOpen}
              onToggle={handleMobileCountryNavToggle}
            />
          )}
          <GridContainer>
            <ContentContainer>
              <Link to="/" title="Go to the home page" onClick={handleMobileNavClose}>
                <LogoContainer
                  style={{
                    height: logoHeight,
                  }}
                >
                  <StaticImage
                    src="../../assets/comic-relief-gsk-logo.png"
                    alt="Comic Relief and GSK"
                    layout="fixed"
                    placeholder="none"
                  />
                </LogoContainer>
              </Link>
              <RightContent>
                <LargeDesktopNavigation smallGap={enableCountryMenu === false}>
                  <NavigationList items={menuLinks} smallGap={enableCountryMenu === false} />
                </LargeDesktopNavigation>

                {enableCountryMenu === true && (
                  <>
                    <DesktopCountrySelector>
                      <Dropdown
                        items={countrySelectorOptions}
                        label={
                          <CountrySelectorLabel>
                            <Icon iconType="globe" />
                          </CountrySelectorLabel>
                        }
                        placeholder="Select country"
                        value={countrySelectorValue}
                        onItemSelect={handleCountrySelectorItem}
                      />
                    </DesktopCountrySelector>

                    <CountryNavigationToggle>
                      <NavigationMobileCountryToggle
                        open={mobileCountryNavOpen}
                        onToggle={handleMobileCountryNavToggle}
                      />
                    </CountryNavigationToggle>
                  </>
                )}

                <MobileNavigationToggle>
                  <NavigationToggle
                    isOpen={mobileNavOpen}
                    onToggle={handleMobileNavToggle}
                    onOpen={handleMobileNavOpen}
                  />
                </MobileNavigationToggle>
              </RightContent>
            </ContentContainer>
          </GridContainer>
        </Container>
      </TopContainer>
      <MobileNavigationContainer>
        <NavigationMobile
          ref={ref}
          isOpen={mobileNavOpen}
          menuLinks={menuLinks}
          navigationHeight={breakpointMd === false ? MOBILE_NAVIGATION_HEIGHT : DESKTOP_NAVIGATION_HEIGHT}
          onClose={handleMobileNavClose}
        />
      </MobileNavigationContainer>
    </>
  );
};

const TopContainer = styled(motion.div)`
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: ${DESKTOP_NAVIGATION_HEIGHT}px;

  ${maxMediaQuery.sm} {
    height: ${MOBILE_NAVIGATION_HEIGHT}px;
  }
`;

const Container = styled(motion.div)`
  position: relative;
  top: 0;
  height: ${DESKTOP_NAVIGATION_HEIGHT}px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary.white};
  z-index: 100;

  ${GridContainer} {
    height: 100%;
    background-color: ${({ theme }) => theme.colors.primary.white};
  }

  ${maxMediaQuery.sm} {
    height: ${MOBILE_NAVIGATION_HEIGHT}px !important;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

const LogoContainer = styled(motion.div)`
  height: ${DESKTOP_LOGO_HEIGHT}px;

  > div {
    height: 100% !important;
    width: auto !important;
    min-width: 190px;

    img {
      position: relative;
      display: block;
      height: 100%;
      width: auto;
    }
  }

  ${maxMediaQuery.sm} {
    height: ${MOBILE_LOGO_HEIGHT}px !important;
    min-width: 130px;
  }
`;

const RightContent = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.primary.white};
  z-index: 100;
`;

const LargeDesktopNavigation = styled.div<{ smallGap: boolean }>`
  display: none;
  height: 100%;
  margin-right: ${({ smallGap }) => (smallGap === true ? '-12px' : '0')};

  ${minMediaQuery.xl} {
    display: flex;
  }
`;

const DesktopCountrySelector = styled.div`
  display: flex;
  height: 100%;

  ${maxMediaQuery.sm} {
    display: none;
  }
`;

const CountrySelectorLabel = styled.span`
  display: block;

  > svg {
    display: block;
  }
`;

const CountryNavigationToggle = styled.div`
  display: flex;
  height: 100%;

  ${minMediaQuery.md} {
    display: none;
  }
`;

const MobileNavigationToggle = styled.div`
  display: none;
  height: 100%;
  margin-right: -20px;

  ${maxMediaQuery.lg} {
    display: flex;
  }

  ${minMediaQuery.md} {
    margin-left: 20px;
  }
`;

const MobileNavigationContainer = styled.div`
  ${minMediaQuery.xl} {
    display: none;
  }
`;

export default Navigation;
